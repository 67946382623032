<template>
  <div>
    <div style="min-height: calc(100vh - 158px);" class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          title="CS Approval Register"
        />
        <DateQuerySetter @onClickGo="getCSList">

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Department</label>
            <v-select
              placeholder="Select Department"
              v-model="departmentId"
              :options="departments"
              label="name"
              :reduce="name => name.id"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Requester</label>
            <v-select
              placeholder="Select Requester"
              v-model="CSUserId"
              :options="CSUsers"
              label="name"
              :reduce="name => name.id"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Select Project</label>
            <v-select
              placeholder="Select Project"
              :options="projects"
              label="name"
              :reduce="name => name.id"
              v-model="projectId"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Status</label>
            <v-select
              placeholder="Select Status"
              v-model="status"
              :options="approvalStatus"
              label="name"
              :reduce="name => name.id"
            />
          </div>
        </DateQuerySetter>

        <div class="row">
          <div class="col-12 float-left">
            <label for="colFormLabel" class="col-form-label">Quick Search</label>
            <input class="form-control" type="text" name="status" v-model="quick_search">
          </div>
        </div>
      </div>

      <ListTable
        :approvalPageId="pageId"
        :workflowDetails="workflowDetails"
        :cs-list="csList"
        @statusUpdated="getCSList()"
      />
      <div class="mb-2"></div>
      <div class="px-2 position-absolute bottom-0">
        <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
        />
      </div>
    </div>
    <GlobalLoader/>
  </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue';
import TitleButton from '@/components/atom/TitleButton.vue'
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue'
import ListTable from '@/components/molecule/procurement/vendor-selection/comparative-statement/approval/CSListingTable.vue'
import Pagination from '@/components/atom/Pagination.vue'
import { useRouter, useRoute } from 'vue-router'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleInventory from "@/services/modules/inventory";
import handleProjects from "@/services/modules/procurement/project";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router = useRouter()
const route = useRoute()
const {fetchCSList, fetchCSUsersList} = handleRequisitions();
const {fetchHome} = handleInventory();
const {fetchProjects} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchWorkflowFromPageId} = handleWorkflow()

const offset = ref(20);
let page = ref(1);
let loading = ref(false);

let csList = ref([]);
const homeContent = ref([]);
const projects = ref([]);
const CSUsers = ref([]);
const departments = ref([]);
const status = ref(null);
const projectId = ref(null);
const CSUserId = ref(null);
const departmentId = ref(null);
const quick_search = ref(null);
const pageId = route.params.pageId;
const workflowDetails = ref({});

const companyId = computed(() => route.params.companyId);
const approvalStatus = computed(() => {
  let approvalStatus = [];
  if(homeContent.value.hasOwnProperty('approval_status')) {
    Object.keys(homeContent.value.approval_status).forEach( id => {
      approvalStatus.push({
        id: id,
        name: homeContent.value.approval_status[id],
      })
    })
  }
  return approvalStatus;
})

function navigateToAddPage() {
  router.push({name: 'cs-add', params: route.params, query: route.query})
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}


async function getCSList() {
  csList.value = [];
  try {
    const res = await fetchCSList(getQuery());
    if (res.status) {
      csList.value = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  query += '&review=' + 1
  query += '&without_user_check=' + 1
  query += '&page_id=' + pageId
  if(projectId.value !== null) {
    query += '&project_id=' + projectId.value
  }
  if(departmentId.value !== null) {
    query += '&department_id=' + departmentId.value
  }
  if(CSUserId.value !== null) {
    query += '&user_id=' + CSUserId.value
  }
  if(status.value !== null) {
    query += '&status=' + status.value
  }
  if (page.value) query += '&page=' + page.value
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getCSList();
  }, 100)
}

onMounted(async () => {
  loading.value = true;
  let query = '?company_id=' + companyId.value
  let workflowQuery = query + `&approval_page_id=${route.params.pageId}`;
  Promise.all([
    getCSList(),
    fetchHome().then( res => {
      homeContent.value = res.data;
    }),
    fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      }
    }),
    fetchCSUsersList(query).then( res => {
      if(res.status) {
        CSUsers.value = res.data;
      }
    }),
    fetchDepartmentList(query).then( res => {
      if(res.status) {
        departments.value = res.data;
      }
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    }),
  ])
    .then ( () => loading.value = false)
    .catch( (err) => {
      loading.value = false
      showError( err.response.message )
    })
    .finally( () => loading.value = false)
})
</script>
