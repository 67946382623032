<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>CS No</th>
          <th>CS Date</th>
          <th>Requester</th>
          <th>Project</th>
          <th>Department</th>
          <th>Current Step</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in csList.data" :key="i">
          <td>{{ item.cs_number }}</td>
          <td>{{ item.cs_date }}</td>
          <td>{{ item.user.name }}</td>
          <td>{{ item.project.name }}</td>
          <td>{{ item.department.name }}</td>
          <td>{{ item.current_step_no}}</td>
          <td>{{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
              @click="goToReview(item.id, item.requisition_id)"
              style="cursor: pointer; margin-left: 10px"
              v-if="item.status !== 'approved'"
              :disabled="item.status === 'rejected'"
            >
              Review
            </button>
            <button class="btn btn-primary btn-sm"
              :disabled="saveButtonLoader"
              @click="goToReview(item.id, item.requisition_id, true)"
              style="cursor: pointer; margin-left: 10px"
              v-if="!['pending', 'returned'].includes(item.status)"
            >
              View
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!csList.total" class="text-center mt-5">No data</p>    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter}      from "vue-router";
import {ref, defineEmits, inject} from "vue";
import handleRequisitions         from "@/services/modules/procurement/requisition";

const props  = defineProps({
  csList: Object,
  workflowDetails: Object,
  approvalPageId: {
    type: String,
    default: null
  },
});
const route  = useRoute();
const router = useRouter();
const $emit = defineEmits(['statusUpdated']);
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const {updateCSStatus} = handleRequisitions();
const saveButtonLoader = ref(false)

const goToReview = (id, requisition_id, viewOnly = false) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let routeName = 'cs-review';
  if(viewOnly) {
    routeName = 'cs-view'
  }

  let query = route.query;
  query.requisitionId = requisition_id;
  query.approval_page_id = props.approvalPageId;
  query.csId = id;
  router.push({
    name: routeName,
    params: param,
    query: query
  })
}
function changeStatus(csId, status){
  if(confirm('Are you sure you want to approve this requisition')) {
    saveButtonLoader.value = true;
    let query = `?company_id=${route.params.companyId}`;
    let statusData = {
      'status' : status,
      'workflow_master_id' : props.workflowDetails.id
    }
    updateCSStatus(statusData, csId, query)
      .then(res => {
        saveButtonLoader.value = false
        if(res.status) {
          showSuccess(res.message)
          $emit('statusUpdated')
        }
        if(!res.status) {
          showError(res.message)
        }
      })
      .catch(err=> {
        saveButtonLoader.value = false
        showError(err)
      })
  }
}
</script>
